export const schoolClassTypes = [
  {
    id: 1,
    name: '1e klas'
  },
  {
    id: 2,
    name: '2e klas'
  },
  {
    id: 3,
    name: 'Group 7'
  },
  {
    id: 4,
    name: 'Group 8'
  },
  {
    id: 5,
    name: 'Group 7/8'
  },
  {
    id: 6,
    name: 'Group 5'
  },
  {
    id: 7,
    name: 'Group 6'
  },
  {
    id: 8,
    name: 'Group 5/6'
  },
  {
    id: 9,
    name: 'Group 6/7'
  }
]